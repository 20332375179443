.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.esri-time-slider {
  cursor: default!important; 
  box-shadow: 0 1px 2px #ff0000!important;
  width: 99vw!important; 
  display: inline!important;
  margin: 0 auto!important;
  bottom: -10px;
}      

.pageContainer{
  height: calc(100vh - 40px);
}

.mapcont{
  width: 100%;
  height: calc(100vh - 40px);
  border: 1px solid;
}

.showElement{
  display: block!important;
}

.hideElement{
  display: none!important;
}

.listBox{
  font-size:12px;
  border-top:1px solid;
} 

.listTopIcon{ 
  color: grey; 
}  

.listItemTitle{
  background-color:rgba(218, 223, 225, .5);
  text-align: left;
  padding: 5px ;
}

.listSubItemTitle{ 
  text-align: left;
  padding: 5px 5px 5px 25px  ;
  border-bottom: 1px solid lightgray;
}

.listItem{ 
  text-align: left;
  margin: 5px ;
}

.boxd{
  border: 1px solid;
  width: 100px;
  display: inline-block;
}

#truckListHistory{
  margin-top:20px;
  height:calc(100vh - 215px);
}



* {
  box-sizing: border-box;
  font-family: "Avenir Next", "Avenir", "Helvetica Neue", sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


